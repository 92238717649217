import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM:`}</p>
    <p>{`2-BB Jump Squats`}</p>
    <p>{`1-Back Squat`}</p>
    <p>{`1-:03 Pause Squat`}</p>
    <p>{`*`}{`using 65% 1RM`}</p>
    <p>{`then at minute 12:00`}</p>
    <p>{`30-20-10 reps each of:`}</p>
    <p>{`Pistols (total)`}</p>
    <p>{`Wall Balls`}</p>
    <p>{`*`}{`100ft shuttle run after each movement.  For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our Pop Up Mobility classes with Glenn will start today at 11:30am
and this Sunday, March 10th, at 12:00am.  This is free for all members,
\\$10 for anyone else, so pop in if you can make it!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit the Ville day at Climb Nulu is this Saturday at 11:30.  We
have a sign up sheet by the white board so let us know if you can make
it.  If you have any questions email Daniel.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      